import Section from '../layouts/Section';
import { useEffect } from 'react';
import { useState } from 'react';
import authService from '../../services/authService';
import classServices from '../../services/classServices';
import Shimmer from './Shimmer';
import { useDispatch, useSelector } from 'react-redux';
import { userLogOut } from '../../utils/store/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import CalenderChart from './CalenderChart';
import { IMAGE_PATH } from '../../constant/constant';
import defaultUser from '../../assets/images/defaultUserProfile.png'
import ScannerImage from './ScannerImage';
import axios from 'axios';
import { NavLink } from 'react-router-dom';


const UploadCv = () => {

  const [file, setFile] = useState(null);
  const [content, setContent] = useState(null);

  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState();

  const [me, setMe] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentClass = useSelector((store) => store.classes?.currentClass?.class);

  useEffect(()=>{
    authService.getMe().then(res=>{
      setMe(res?.success?.user)
    })
  }, []) 

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCheck = async () => {

  }

  const handleUpload = async () => {
    if (!file) {
      return;
    }

  // Display the scanner image
  setLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('companyId',currentClass?._id);

    try {
        classServices.uploadCv(formData).then(res=>{         
          if(res){                           
            setContent(res?.doc);
            setLoading(false);    
            setMsg(res?.message);       
          } else if(res !== ''){            
            setMsg(res.error);
            setLoading(false);        
          } 
          })      
    } catch (error) {      
      console.error('Error uploading file:', error);
      
   }
    //finally {
    //   // Hide the scanner image
     
    //   setLoading(false);
    // }
  };
  if (!me) return <Section><Shimmer count={3}/></Section>



  return (
    <Section>
      <div className="bg-blue-50 p-5 rounded-t-2xl">Candidate Upload CV</div>
      <div className="bg-blue-50 p-1 rounded-md grid gap-5">
        <div className="border-2 border-white bg-tileColor p-2 rounded-lg md:grid md:grid-cols-2 gap-5">
          <div className="flex flex-col md:flex-row gap-5">
            <div className='flex items-center gap-5'>
            {loading === true && <ScannerImage />}
            {loading === false && content && <img draggable='false' src={(me?.avatar && `${IMAGE_PATH}profiles/${me?.avatar}`) || defaultUser} alt="user profile" className="max-w-[7rem] rounded-xl border-2 border-white"/> }
              {/* <p className='font-bold text-primary text-9xl md:hidden'>B</p> */}
            </div>
            {content?.doc?
            (<div className="flex flex-col gap-5" style={{marginTop: '-15px'}}>
              <p className='text-2xl text-textColor'>{content?.doc?.name}</p>
              <div className=' flex flex-row items-center'>
              <label className='w-20 xl:w-30 xl:text-xl text-slate-800'>Email:</label>
              <p>{content?.doc?.contact? content?.doc?.contact?.email : content?.email }</p>
              {content?.doc?.contactDetails?
                (<p>
                  {content?.doc?.contactDetails?.email}
                </p>) : "" }
              {content?.doc?.contact_details?
                (<p>
                  {content?.doc?.contact_details?.email}
                </p>) : "" }
                {content?.doc?.contact_information?
                (<p>
                  {content?.doc?.contact_information?.email}
                </p>) : "" } 

            </div>
            <div className='flex flex-row items-center'>
              <label className='w-20 xl:w-30 xl:text-xl text-slate-800'>Mobile:</label>
              {content?.doc?.contact?
              (<p>{content?.doc?.contact? content?.doc?.contact?.mobile : content?.doc?.mobile}
              {content?.doc?.contact_number? content?.doc?.contact_number: content?.doc?.mobile }</p>) : content?.doc?.contact_number? content?.doc?.contact_number : content?.doc?.mobile }
              {content?.doc?.contactDetails?
                (<p>
                  {content?.doc?.contactDetails?.mobile}
                </p>) : "" }
              {content?.doc?.contact_details?
                (<p>
                  {content?.doc?.contact_details?.mobile}
                </p>) : "" }
                {content?.doc?.contact_information?
                (<p>
                  {content?.doc?.contact_information?.mobile}
                </p>) : "" }
            </div>
            <div className=' flex :flex-row items-center'>
              <label className='w-35 xl:w-30 xl:text-xl text-slate-800'>Desigination:</label>
              <p className='text-slate-600'>{content?.doc?.designation}</p>
            </div>
            <div className=' flex flex-row items-center'>
              <label className='w-20 xl:w-30 xl:text-xl text-slate-800' style={{marginBottom: '15px'}}>Total IT Exp:</label>
              <p>{content?.doc?.total_IT_experience? content?.doc?.total_IT_experience : content?.doc?.experience}</p>
            </div> 

            <div className="grid grid-cols-1 gap-5">
            <div>                
              <p className='text-center' style={{color:'green', marginTop: '-30px'}}>{msg}</p>
            </div>              
            </div> 

            </div>
            
            ) : 
            (<div className="flex flex-col gap-5" style={{marginTop: '-15px'}}>
            <p className='text-2xl text-textColor uppercase'>{content?.name}</p>
            <div className=' flex flex-row items-center'>
            <label className='w-20 xl:w-30 xl:text-xl text-slate-800'>Email:</label>
            <p>{content?.contact? content?.contact?.email : content?.email }</p>
            {content?.contactDetails?
              (<p>
                {content?.contactDetails?.email}
              </p>) : "" }
            {content?.contact_details?
              (<p>
                {content?.contact_details?.email}
              </p>) : "" }
              {content?.contact_information?
              (<p>
                {content?.contact_information?.email}
              </p>) : "" } 

          </div>
          <div className='flex flex-row items-center'>
            <label className='w-20 xl:w-30 xl:text-xl text-slate-800'>Mobile:</label>
            {content?.contact?
            (<p>{content?.contact.mobile? content?.content?.mobile : content?.mobile }
            {content?.contact_number? content?.contact_number: content?.mobile }</p>) : content?.mobile }
            {content?.contactDetails?
              (<p>
                {content?.contactDetails?.mobile}
              </p>) : "" }
            {content?.contact_details?
              (<p>
                {content?.contact_details?.mobile}
              </p>) : "" }
              {content?.contact_information?
              (<p>
                {content?.contact_information?.mobile}
              </p>) : "" }
          </div>
          <div className=' flex :flex-row items-center'>
            <label className='w-35 xl:w-30 xl:text-xl text-slate-800'>Desigination:</label>
            <p className='text-slate-600'>{content?.designation}</p>
          </div>
          <div className=' flex flex-row items-center'>
            <label className='w-20 xl:w-30 xl:text-xl text-slate-800'>Total IT Exp:</label>
            <p>{content?.total_IT_experience? content?.total_IT_experience : content?.experience}</p>            
          </div> 

           <div className="grid grid-cols-1 gap-5">
            <div>                
              <p className='text-center' style={{color:'green', marginTop: '-30px'}}>{msg}</p>
            </div>              
            </div>          
             
            </div>)
            }           

          </div>
          <div className="hidden text-center md:flex items-center justify-center">
            {/* <p className="font-bold uppercase text-9xl text-primary">B</p> */}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-5">                 
          <p className='text-center' style={{color:'red', marginTop: '-30px', textAlign: 'left'}}>{content?.message && 'Please upload a valid file format .docx extension only..'}</p>
        </div> 

        <div className="grid md:grid-cols-2 gap-10">
          { content == null? 
          <div className="border-2 border-white bg-tileColor p-2  rounded-lg md:grid md:grid-cols-2 gap-1 md:p-10">            
            <div className="flex justify-between mt-1 ">
              <input type="file" className="bg-gray-200 border-gray-300 outline-primary w-full py-1 focus:bg-blue-50 file:bg-violet-50 file:text-primary file:border-0 file:rounded file:px-3 file:py-2 file:font-bold  bg-transparent border-0" onChange={handleFileChange} />
              <button className='bg-primary text-white flex items-center px-1 rounded-md' onClick={handleUpload}>Upload</button> 
            </div>
          </div> : '' }

         { content == null?
           "" :
          <div className="border-2 border-white bg-tileColor p-2  rounded-lg md:grid md:grid-cols-2 gap-1 md:p-10 ">
            {/* <button className='bg-primary text-white flex items-center px-1 rounded-md' onClick={handleCheck}>Lets Go To Profile Check</button> */}            
              <NavLink
            className={`flex gap-5 items-center py-3 px-3 md:px-8 rounded shadow bg-white text-textColor font-bold  w-full text-left hover:bg-indigo-500 hover:text-white  SideBar-tab h-[3rem]`}
            to={`/profilecheck/${content?.doc?._id || content?._id}`}
            >
            {/* <i className="fa-solid fa-upload"></i> */}
            <span className="side-bar-title">Lets Go To AI Scan</span>
          </NavLink>
          </div> } 

        </div>
      </div>
    </Section>
  );
};

export default UploadCv;
