import { NavLink } from "react-router-dom";
import styles from "../../Footer.module.css";
import image from "../../assets/images/logo-white.png";

const Footer = () => {
  return (
    <>
      <div className={styles.container}>
        {/* <div className={styles.item}>
          <div className="max-w-[7rem] flex items-center ms-4 md:ms-0">
            <img src={image} style={{ height: "50px" }} alt="" />
          </div>
        </div> */}

        <section className="section-padding footer-list">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div
                  className="footer-logo mb-4"
                  style={{ marginTop: "40px", marginLeft: "40px" }}
                >
                  <a className="logo" href={"/"}>
                    <img alt="" src={image} className="img-fluid" />
                  </a>
                </div>
                <div className={styles.card}>
                  <p className={styles.text}>
                    Address: 901, Unique Prospero Society,
                    <br /> Porwal Road, Lohegaon , Pune Maharashtra, 411047
                  </p>
                  <p className={styles.text}>
                    <a href="#" className="text-dark">
                      Mobile: +91 8826844273
                    </a>
                  </p>
                  <p className={styles.text}>
                    <a href="#" className="text-dark">
                      Email: info@osstechhire.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className={styles.item}>
          <div className={styles.card}>
            <h2 className={styles.title}>PRODUCTS</h2>
            <p className={styles.text}>
              <a href="https://osstechhire.com" target={`_self`}>
                OSSTechHire
              </a>
              <br />{" "}
              <a href="https://osstube.com" target={`_blank`}>
                OSSTube
              </a>
              <br />{" "}
              <a href="https://osschat.in" target={`_self`}>
                OSSChat
              </a>
            </p>
          </div>
          <div className={styles.card}>
            <h2 className={styles.title}>COMPANY</h2>
            <p className={styles.text}>
              <NavLink to="/home">Home</NavLink> <br />
              <NavLink to="/aboutus">About Us</NavLink>
              <br /> Careers
              <br /> <NavLink to="/privacy">Privacy Policy</NavLink> <br />
              <NavLink to="/terms_conditions">Terms & Conditions</NavLink>
            </p>
          </div>
          <div className={styles.card}>
            <h1 className={styles.title}>CONTACT US</h1>
            <p className={styles.text}>
              Online Community
              <br />{" "}
              <a href="https://osschat.in" target={`_blank`}>
                https://osschat.in
              </a>
            </p>
          </div>
          {/* <div className={styles.card}>
          <h1 className={styles.title}>WORKING HOURS</h1>
          <p className={styles.text}>
            MONDAY UNTIL FRIDAY
            <br /> 9:00 – 22:00
          </p>
          <p className={styles.text}>
            SATURDAY - SUNDAY
            <br /> 12:00 – 24:00
          </p>
        </div> */}
        </div>
      </div>
      <div>© 2024 All rights reserved. Onlinesoftservices Private Limited</div>
    </>
  );
};

export default Footer;
